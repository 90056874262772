.nav-font-size {
    font-size: 30px !important;
    color: $background-font;
    cursor: pointer;
}

@media(max-height: 512px) and (min-width: $mobile-min-width){
    .il-navbar .il-navbar-fixed-bottom{
        position: relative;
        bottom: 24px;
        left: -1px;
        z-index: 1030;
        text-align: center;
    }
    .il-navbar{
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media(min-height: 513px){
    .il-navbar .il-navbar-fixed-bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1030;
        width: 64px;
        margin: 20px 0;
        text-align: center;
    }
}

@media (min-width: $mobile-min-width) {
    .il-navbar {
        background-color: nth($navbarColor, 1);
    }
    .navbar-logo {
        display: none;
    }
}

@media (max-width: $mobile-max-width) {
    .il-navbar {
        padding: 0;
        font-size: 1.8em;
        background-color: nth($headerColor, 1);
    }
    .navbar-logo {
        display: block;
    }
    .navbar-default .navbar-nav>li>a {
        color: $background-font;
    }
    .navbar-header {
        background-color: #E2E1E1;
    }
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        border-color: #6F6F6F;
        background-color: #6F6F6F;
    }

    .navbar-collapse{
        border: 0;
    }
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: $icon-bar;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: nth($headerColor, 1);
    color: $background-font;
}

.navbar-default .navbar-toggle {
    background-color: nth($headerColor, 1);
    color: $background-font;
}











    
    
    .close-icon {
        display: block;
        width: 22px;
        color: #000;
        font-size: 18px!important;
    }
    

    .il-navbar .navbar-nav .ilicon{
        font-size: 16px;
    }

    .navbar-default .navbar-nav>li>a {
        color: #fafafa;
        font-size: 16px;
    }
    
    .navbar-default .navbar-nav span.ilicon:focus,
    .navbar-default .navbar-nav span.ilicon:hover
    {
        color: #E52634;
    }
    
    .navbar-default .navbar-nav>.active>a>span,
    .navbar-default .navbar-nav>.active>a>span:focus,
    .navbar-default .navbar-nav>.active>a>span:hover {
        color: #E52634;
        background-color: #fafafa;
    }
    
    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:focus,
    .navbar-default .navbar-nav>.active>a:hover {
        color: #E52634;
        background-color: #fafafa;
    }
    
    .navbar-default .navbar-nav>li>a:hover,
    .navbar-default .navbar-nav>li>a:focus {
        color: #E52634;
        background-color: #fafafa;
    }
    
    .navbar-default .navbar-nav>li>span:hover,
    .navbar-default .navbar-nav>li>span:focus {
        color: #E52634;
        background-color: #fafafa;
    }
    
    .workspace:hover>.ilicon-img-extended-32.ilicon-img-extended-valuation-received-white-32{
        display: inline-block;
        background-image: url(../images/ilicon-extended-32/valuation-received-red.png);
    }

    .workspace:hover>.ilicon-img-white-32.ilicon-img-doc_move-white-32 {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon/red-32/sprite.svg);
        width: 32px;
        height: 32px;
        background-position: -192px -48px;
    }

    .workspace:hover>.ilicon-img-white-32.ilicon-img-download-doc-white-32{
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon/red-32/sprite.svg);
        width: 32px;
        height: 32px;
        background-position: -528px -48px;
    }

    .workspace:hover>.ilicon-img-white-32.ilicon-img-help-white-32{
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon/red-32/sprite.svg);
        width: 32px;
        height: 32px;
        background-position: -192px -96px;
    }