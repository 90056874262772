.ilicon-img-black-16  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/black-16/sprite.svg);
}
.ilicon-img-access-report-black-16 {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.ilicon-img-admin-black-16 {
  width: 16px;
  height: 16px;
  background-position: -32px 0;
}
.ilicon-img-admin-warning-1-black-16 {
  width: 16px;
  height: 16px;
  background-position: -64px 0;
}
.ilicon-img-acrobat-black-16 {
  width: 16px;
  height: 16px;
  background-position: -96px 0;
}
.ilicon-img-caret-black-16 {
  width: 16px;
  height: 16px;
  background-position: -128px 0;
}
.ilicon-img-caret-down-black-16 {
  width: 16px;
  height: 16px;
  background-position: -160px 0;
}
.ilicon-img-check-black-16 {
  width: 16px;
  height: 16px;
  background-position: -192px 0;
}
.ilicon-img-chevron-down-black-16 {
  width: 16px;
  height: 16px;
  background-position: -224px 0;
}
.ilicon-img-chevron-left-black-16 {
  width: 16px;
  height: 16px;
  background-position: -256px 0;
}
.ilicon-img-chevron-right-black-16 {
  width: 16px;
  height: 16px;
  background-position: -288px 0;
}
.ilicon-img-chevron-up-black-16 {
  width: 16px;
  height: 16px;
  background-position: -320px 0;
}
.ilicon-img-close2-black-16 {
  width: 16px;
  height: 16px;
  background-position: -352px 0;
}
.ilicon-img-filter-applied-black-16 {
  width: 16px;
  height: 16px;
  background-position: -384px 0;
}
.ilicon-img-cloud-upload-mobile-black-16 {
  width: 16px;
  height: 16px;
  background-position: -416px 0;
}
.ilicon-img-comments-black-16 {
  width: 16px;
  height: 16px;
  background-position: -448px 0;
}
.ilicon-img-confirm-black-16 {
  width: 16px;
  height: 16px;
  background-position: -480px 0;
}
.ilicon-img-copy-black-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -32px;
}
.ilicon-img-single-line-black-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}
.ilicon-img-delete-black-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}
.ilicon-img-descend-black-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}
.ilicon-img-doc_move-black-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}
.ilicon-img-doc-add-black-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}
.ilicon-img-doc-extension-black-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}
.ilicon-img-document-black-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}
.ilicon-img-doc-updated-black-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}
.ilicon-img-doc-upload-black-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}
.ilicon-img-download-black-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}
.ilicon-img-download-doc-black-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}
.ilicon-img-downloaded-indicator-black-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}
.ilicon-img-download-offline-black-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}
.ilicon-img-edit-black-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}
.ilicon-img-eforms-black-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}
.ilicon-img-ellipsis-black-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -64px;
}
.ilicon-img-excel-logo-black-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}
.ilicon-img-export-details-black-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}
.ilicon-img-hamburger-black-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}
.ilicon-img-help-black-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}
.ilicon-img-image-black-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}
.ilicon-img-logout-black-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}
.ilicon-img-mail-black-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}
.ilicon-img-minus-black-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}
.ilicon-img-move-black-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}
.ilicon-img-plus-black-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}
.ilicon-img-ppt-logo-black-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}
.ilicon-img-ilicon_picture-black-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}
.ilicon-img-reply-black-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}
.ilicon-img-search-black-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}
.ilicon-img-sync-black-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}
.ilicon-img-user-black-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -96px;
}
.ilicon-img-word-logo-black-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

