.ilicon-img-green-32  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/green-32/sprite.svg);
}
.ilicon-img-access-report-green-32 {
  width: 32px;
  height: 32px;
  background-position: 0 0;
}
.ilicon-img-admin-green-32 {
  width: 32px;
  height: 32px;
  background-position: -48px 0;
}
.ilicon-img-admin-warning-1-green-32 {
  width: 32px;
  height: 32px;
  background-position: -96px 0;
}
.ilicon-img-acrobat-green-32 {
  width: 32px;
  height: 32px;
  background-position: -144px 0;
}
.ilicon-img-caret-green-32 {
  width: 32px;
  height: 32px;
  background-position: -192px 0;
}
.ilicon-img-caret-down-green-32 {
  width: 32px;
  height: 32px;
  background-position: -240px 0;
}
.ilicon-img-check-green-32 {
  width: 32px;
  height: 32px;
  background-position: -288px 0;
}
.ilicon-img-chevron-down-green-32 {
  width: 32px;
  height: 32px;
  background-position: -336px 0;
}
.ilicon-img-chevron-left-green-32 {
  width: 32px;
  height: 32px;
  background-position: -384px 0;
}
.ilicon-img-chevron-right-green-32 {
  width: 32px;
  height: 32px;
  background-position: -432px 0;
}
.ilicon-img-chevron-up-green-32 {
  width: 32px;
  height: 32px;
  background-position: -480px 0;
}
.ilicon-img-close2-green-32 {
  width: 32px;
  height: 32px;
  background-position: -528px 0;
}
.ilicon-img-filter-applied-green-32 {
  width: 32px;
  height: 32px;
  background-position: -576px 0;
}
.ilicon-img-cloud-upload-mobile-green-32 {
  width: 32px;
  height: 32px;
  background-position: -624px 0;
}
.ilicon-img-comments-green-32 {
  width: 32px;
  height: 32px;
  background-position: -672px 0;
}
.ilicon-img-confirm-green-32 {
  width: 32px;
  height: 32px;
  background-position: -720px 0;
}
.ilicon-img-copy-green-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -48px;
}
.ilicon-img-single-line-green-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -48px;
}
.ilicon-img-delete-green-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -48px;
}
.ilicon-img-descend-green-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -48px;
}
.ilicon-img-doc_move-green-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -48px;
}
.ilicon-img-doc-add-green-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -48px;
}
.ilicon-img-doc-extension-green-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -48px;
}
.ilicon-img-document-green-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -48px;
}
.ilicon-img-doc-updated-green-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -48px;
}
.ilicon-img-doc-upload-green-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -48px;
}
.ilicon-img-download-green-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -48px;
}
.ilicon-img-download-doc-green-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -48px;
}
.ilicon-img-downloaded-indicator-green-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -48px;
}
.ilicon-img-download-offline-green-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -48px;
}
.ilicon-img-edit-green-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -48px;
}
.ilicon-img-eforms-green-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -48px;
}
.ilicon-img-ellipsis-green-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -96px;
}
.ilicon-img-excel-logo-green-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -96px;
}
.ilicon-img-export-details-green-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -96px;
}
.ilicon-img-hamburger-green-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -96px;
}
.ilicon-img-help-green-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -96px;
}
.ilicon-img-image-green-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -96px;
}
.ilicon-img-logout-green-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -96px;
}
.ilicon-img-mail-green-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -96px;
}
.ilicon-img-minus-green-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -96px;
}
.ilicon-img-move-green-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -96px;
}
.ilicon-img-plus-green-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -96px;
}
.ilicon-img-ppt-logo-green-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -96px;
}
.ilicon-img-ilicon_picture-green-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -96px;
}
.ilicon-img-reply-green-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -96px;
}
.ilicon-img-search-green-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -96px;
}
.ilicon-img-sync-green-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -96px;
}
.ilicon-img-user-green-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -144px;
}
.ilicon-img-word-logo-green-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -144px;
}

