.hearBar {
    background: nth($headerColor, 1);
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    color: nth($headerColor, 2);
}

.logo {
    background: url($logoUrl);
    background-size: 230px;
    width: 220px;
    height: 60px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.navbar-header {
    margin: 5px 0;
    float: none;
    height: 57px;
}


.isLogin {
    display: none;
}

.notLogin {
    display: block;
}

@media (max-width: $mobile-max-width) {
    .notLogin {
        display: none;
    }
}