// applicant details
#personal-box {
    margin: 8px 0;
    font-size: 1.25em;

    .personal-icon {
        cursor: pointer;
        padding: 10px 0;
        height: 100%;
        text-align: center;
    }
    .personal-content {
        padding: 0;
        cursor: pointer;
        label {
            margin: 0;
        }
        ;
        p {
            color: #7d7d7d;
        }
        ;
        div {
            font-weight: bold;
        }
    }
}

.portfolio-summary-text {
    color: #00864f;
    margin-bottom:10px;
}

.mobile-portfolio-summary-background{
    background:#F9F9F9;
}

//Assets list view
.property-no-icon-address-line{
    padding-left: 35px;
}

//portfolio-summary
#portfolio-summary {
    h4 {
        font-size: 1em;
        font-weight: bold;
        margin: 0px;
        color:black;
    }
    p {
        font-size: 1em;
        margin: 0px;
    }
    .portfolio-summary-header{
        // padding-top: 10px;
        background: #f9f9f9;
    }

    .sub-header-title{
        border-left: none;
        border-right: none;
    }

    .right-border{
        border-right: 1px solid #d7d7d7;
    }
}

//modal
.modal-fixed {
    position: fixed;
}

.il-modal-fullscreen .modal-header {
    padding: 30px 15px 15px;
    color: #fff;
    background: #555;
    border-bottom: 1px solid #e5e5e5;
    border-top-left-radius: 6px;
}

@media (max-width: 768px) {
    .il-modal-md,
    .il-modal-fullscreen {
        .modal-dialog {
            top: 0;
        }
    }

    .personal-content {
        font-size: 0.8em !important;
    }

    .col-xs-clear-padding {
        padding:0;
    }

    .mobile-font-size {
        font-size: 12px;
    }

    .disabled-button-text{
        color: #7f8c8d;
    }
}

.select-item {
    h5:hover {
        color: #f49623;
        cursor: pointer;
    }
    .block {
        width: 16px;
        height: 16px;
        display: inline-block;
    }
}

// form
#personalDetailsModal,
#assetDetailsModal,
#propertyDetailsModal {
    .form-group {
        padding: 0 20px;
        label {
            text-align: left;
        }
    }
    .form-description {
        color: #b9aeae;
        font-size: 14px;
        font-weight: initial;
    }
    .form-inline {
        margin: 15px 0;
        input {
            border: 1px solid #c9302c;
        }
    }
    .textarea-additional-information {
        height: 120px;
    }

    .textarea-postcode{
        width: 50%;
    }
}

.customer-profile-header {
    border: 1px solid #d7d7d7;
}

.sub-header-title {
    font-size: 16px;
    font-weight: bold;
    background: #e7e7e7;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}

.sub-header-content {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}

.sub-header-content:hover {
    background: #d2cece;
    cursor: pointer;
}

.clear-float {
    clear: both;
}

.list-view {
    margin-bottom: 20px;
}

.subject-row {
    color: nth($secondary, 3);
}

.cursor-pointer {
    cursor: pointer;
}

.new-property-content {
    line-height: 36px;
    margin-left: 42px;
}

.mobile-customer-profile-title {
    background: #E61E2D;
    color: #F9F9F9;
    font-size: 1.2em;
    padding: 10px;
    display: inline-flex;
    width: 100%;
    line-height: 18px;
}

.mobile-customer-profile-btn {
    // padding: 10px;
    height: 40px;
    background: #e7e7e7;
    font-size: 1.3em;
    text-align: center;
}

.mobile-customer-profile-btn-col {
    border-right: 1px solid;
    height: 40px;
    line-height: 40px;
}

.mobile-customer-profile-btn-end {
    height: 40px;
    line-height: 40px;
}

.mobile-customer-profile-case-card,
.mobile-customer-profile-caseCard-left,
.mobile-customer-profile-caseCard-right {
    // height: 85px !important;
    height: 100%;
    min-height: 75px;
}

.mobile-customer-profile-properties-title {
    font-size: 15px;
    font-weight: bold;
    background: #e7e7e7;
    padding: 0 25px;
}

.mobile-properties-header {
    margin-bottom: 0px;
}

//confirmation modal
.confirmationMessage {
    .icon {
        float: left;
    }
    .message {
        display: inline-block;
        padding: 0;
        margin: auto 5px;
        height: 36px;
        line-height: 36px;
    }
}

.textarea-border {
    border: 1px solid #DADADA;
    color: #888;
    margin-bottom: 16px;
    margin-right: 6px;
    margin-top: 2px;
    outline: 0 none;
    padding: 3px 3px 3px 5px;
    font-size: 14px;
    box-shadow: inset 0px 1px 4px #ECECEC;
    -moz-box-shadow: inset 0px 1px 4px #ECECEC;
    -webkit-box-shadow: inset 0px 1px 4px #ECECEC;
    border-radius: 0;
}


//modal
.vertical-align-content {
    vertical-align: middle;
}


.modal-cpf-decline-checkboxes{
    margin-left: 10px;
}

.modal-title-warning{
    color: #E61E2D;
}

.form-title{
    margin-bottom: 0;
}

.form-title-description{
    color: #b9aeae;
    font-size: 18px;
    font-weight: bold;
}


.row-validation-fail {
    background: lighten(nth($primary, 1), 30%) !important;
}

.row-validation-warn {
    background: lighten(nth($secondary, 3), 30%);
}


.avm-not-edit-background{
    background: #e7e7e7;
    height: inherit;
}

.avm-edit-background{
    background: white;
    cursor: pointer;
}

.avm-table-margin {
    margin-bottom: 20px;
}

.avm-table-content input{
    height: 25px;
    border: 0;
}

.avm-table-row {
    height: 25px;
}

.tb-same-height-as-text{
    height: 17px;
}

.high-customer-yield{
    color: orange;
    font-weight: bold;
}


.underwriter-summary-input-textbox{
    padding: 0px;
    padding-left: 12px
}

.cpf-download-template{
    margin-right: 15px;
}

.placeholder-text-display{
    .form-control::-webkit-input-placeholder {
        color: #555555;
    }
    .form-control:-moz-placeholder {
        /* FF 4-18 */
        color: #555555;
        opacity: 1;
    }
    .form-control::-moz-placeholder {
        /* FF 19+ */
        color: #555555;
        opacity: 1;
    }
    .form-control:-ms-input-placeholder {
        /* IE 10+ */
        color: #555555;
    }
    .form-control::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #555555;
    }
    .form-control::placeholder {
        /* modern browser */
        color: #555555;
    }
}

.paf-loading {
    z-index: 1040;
}

.postcode-has-warning {
    border: 1px orange solid;
}

.postcode-has-error {
    border: 1px #a94442 solid !important;
}

.cpf-modal-address {
    margin: 10px;
}

.btn-lookup {
    min-width: 70px !important;
    max-width: 120px !important;
}

.cmb-lender-has-error {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.paf-fail-description {
    color: #f49623;
    margin-top: 10px;
}

.underwriter-personal-details-tax-region-dropdown{
    width: 50%!important;
}
.underwriter-personal-details-text{
    font-size: 14px;
    color: #00864f;
}

.epc-address-padding-left {
    padding-left: 3.5rem;
}

.epc-address-padding-left-no-icon {
    padding-left: 5.5rem;
}

.epc-rating {
    height: 100%;
}

.epc-rating-text-color{
    color:white
}

.epc-rating-unknown {
    background: #505050;
}

.epc-rating-a {
    background: #00792c
}

.epc-rating-b {
    background: #00a246
}

.epc-rating-c {
    background: #98be0d
}

.epc-rating-d {
    background: #ffcc00
}

.epc-rating-e {
    background: #f7b161
}

.epc-rating-f {
    background: #ed7e00
}

.epc-rating-g {
    background: #e2001a
}