.content-box {
    background: #e2e1e1;
    padding: 5px 0;
    margin: 5px auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
    .title {
        color: #E61E2D;
        a {
            width: 100px;
            float: right;
            text-align: left;
            padding: 0px 5px;
            p {
                display: inline;
                margin: 0px 10px;
                vertical-align: middle;
                font-size: 15px;
                margin: 0;
                font-weight: bold;
            }
        }
    }
}

.accept {
    color: #449463;
}

.fail {
    color: #E61E2D;
}

.hearder-title-right {
    font-size: 1.15em;
    font-weight: 500;
    width: 115px;
}

.epc-rating-unknown-label {
    color: #505050;
}

.epc-rating-a-label {
    color: #00792c
}

.epc-rating-b-label {
    color: #00a246
}

.epc-rating-c-label {
    color: #98be0d
}

.epc-rating-d-label {
    color: #ffcc00
}

.epc-rating-e-label {
    color: #f7b161
}

.epc-rating-f-label {
    color: #ed7e00
}

.epc-rating-g-label {
    color: #e2001a
}