    @media (max-width: $mobile-max-width) {
        .modal-dialog {
            margin: 60px auto auto;
        }
    }

    @media (min-width: $mobile-min-width) {
        .modal-dialog {
            margin: 153px auto auto;
        }
    }

    .optionCard {
        height: 220px;
        background: nth($optionCard, 1);
        color: nth($optionCard, 2);
        padding: 40px;
        margin: 15px 0;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    .optionCard>span {
        font-size: 5em;
        color: nth($optionCard, 2);
        ;
    }

    .optionCard:hover {
        box-shadow: 0 0 36px nth($optionCard, 3);
        ;
    }

    h3 {
        font-size: 20px;
    }

    .fade {
        opacity: 1;
    }

    .mandatory-char {
        color: nth($themeColor, 1);
    }

    .md-virtual-repeat-container.md-autocomplete-suggestions-container  {
        z-index: 9999;
    }

    .flex { display: flex; }
    .form-control { min-width: 0; }

    .application-number {
        font-size: 18pt;
        margin-bottom:0.5em;
    }

    .application-number-1{
        width: 4em;
        margin-right:1em;
    }

    .application-number-2{
        margin-left:1em;
        margin-right:1em;
    }

    .application-number-3{
        width: 4em;
        margin-left:1em;
    }

    .status-label {
        text-align: start;
        padding-left: 15px;
    }

    .error-label {
        color: #a94442;
    }