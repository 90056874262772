.ilicon-img-white-32 {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/white-32/sprite.svg);
  fill: greenyellow;
}
.ilicon-img-access-report-white-32 {
  width: 32px;
  height: 32px;
  background-position: 0 0;
}
.ilicon-img-admin-white-32 {
  width: 32px;
  height: 32px;
  background-position: -48px 0;
}
.ilicon-img-admin-warning-1-white-32 {
  width: 32px;
  height: 32px;
  background-position: -96px 0;
}
.ilicon-img-acrobat-white-32 {
  width: 32px;
  height: 32px;
  background-position: -144px 0;
}
.ilicon-img-caret-white-32 {
  width: 32px;
  height: 32px;
  background-position: -192px 0;
}
.ilicon-img-caret-down-white-32 {
  width: 32px;
  height: 32px;
  background-position: -240px 0;
}
.ilicon-img-check-white-32 {
  width: 32px;
  height: 32px;
  background-position: -288px 0;
}
.ilicon-img-chevron-down-white-32 {
  width: 32px;
  height: 32px;
  background-position: -336px 0;
}
.ilicon-img-chevron-left-white-32 {
  width: 32px;
  height: 32px;
  background-position: -384px 0;
}
.ilicon-img-chevron-right-white-32 {
  width: 32px;
  height: 32px;
  background-position: -432px 0;
}
.ilicon-img-chevron-up-white-32 {
  width: 32px;
  height: 32px;
  background-position: -480px 0;
}
.ilicon-img-close2-white-32 {
  width: 32px;
  height: 32px;
  background-position: -528px 0;
}
.ilicon-img-filter-applied-white-32 {
  width: 32px;
  height: 32px;
  background-position: -576px 0;
}
.ilicon-img-cloud-upload-mobile-white-32 {
  width: 32px;
  height: 32px;
  background-position: -624px 0;
}
.ilicon-img-comments-white-32 {
  width: 32px;
  height: 32px;
  background-position: -672px 0;
}
.ilicon-img-confirm-white-32 {
  width: 32px;
  height: 32px;
  background-position: -720px 0;
}
.ilicon-img-copy-white-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -48px;
}
.ilicon-img-single-line-white-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -48px;
}
.ilicon-img-delete-white-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -48px;
}
.ilicon-img-descend-white-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -48px;
}
.ilicon-img-doc_move-white-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -48px;
}
.ilicon-img-doc-add-white-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -48px;
}
.ilicon-img-doc-extension-white-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -48px;
}
.ilicon-img-document-white-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -48px;
}
.ilicon-img-doc-updated-white-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -48px;
}
.ilicon-img-doc-upload-white-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -48px;
}
.ilicon-img-download-white-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -48px;
}
.ilicon-img-download-doc-white-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -48px;
}
.ilicon-img-downloaded-indicator-white-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -48px;
}
.ilicon-img-download-offline-white-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -48px;
}
.ilicon-img-edit-white-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -48px;
}
.ilicon-img-eforms-white-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -48px;
}
.ilicon-img-ellipsis-white-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -96px;
}
.ilicon-img-excel-logo-white-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -96px;
}
.ilicon-img-export-details-white-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -96px;
}
.ilicon-img-hamburger-white-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -96px;
}
.ilicon-img-help-white-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -96px;
}
.ilicon-img-image-white-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -96px;
}
.ilicon-img-logout-white-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -96px;
}
.ilicon-img-mail-white-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -96px;
}
.ilicon-img-minus-white-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -96px;
}
.ilicon-img-move-white-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -96px;
}
.ilicon-img-plus-white-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -96px;
}
.ilicon-img-ppt-logo-white-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -96px;
}
.ilicon-img-ilicon_picture-white-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -96px;
}
.ilicon-img-reply-white-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -96px;
}
.ilicon-img-search-white-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -96px;
}
.ilicon-img-sync-white-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -96px;
}
.ilicon-img-user-white-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -144px;
}
.ilicon-img-word-logo-white-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -144px;
}

