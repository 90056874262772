
    .wrapper {
        display: block;
        position: relative;
        margin: 0 auto;
        font-family: Arial, sans-serif;
        color: $modalHeader;
        margin-top: 20px;
        font-size: 16px;
    }

    .login-header-title {
        display: block;
        font-size: 150%;
        font-weight: bolder;
        -webkit-margin-before: 0.67em;
        -webkit-margin-after: 0.67em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        font-family: 'Planer', Arial, sans-serif;
    }

    .login-header-subtitle {
        font-size: 120%;
        display: block;
        -webkit-margin-before: 0.83em;
        -webkit-margin-after: 0.83em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        font-family: 'Planer', Arial, sans-serif;
        font-weight: bold;
    }

    .login-header {
        text-align: center;
        margin-bottom: 10px;
    }

    .oauth-brands .brand {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 94px;
        border-radius: 2px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        overflow: hidden;
    }

    .oauth-brands .brand img {
        display: inline-block;
    }

    .oauth-brands .brand img:first-child {
        width: 220px;
        height: 60px;
    }

    .oauth-brands .brand img:nth-child(2) {
        height: 60px;
    }

    .oauth-brands .brand:last-child {
        margin-left: 50px;
        overflow: visible;
    }

    .oauth-brands .brand:last-child:before {
        display: block;
        position: absolute;
        content: "\00a0";
        width: 0;
        height: 0;
        border: 10px solid #A8A8A8;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        left: -30px;
        top: 20px;
    }


    .login-form {
        display: block;
        width: 100%;
        padding: 30px;
        padding-bottom: 40px;
        background-color: #efeee7;
        color: $modalHeader;
        font-weight: 300;
        font-size: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
    }

    .no-brand .brand:first-child {
        display: none;
    }

    .no-brand .brand:last-child {
        margin-left: 0;
    }

    .no-brand .brand:last-child:before {
        display: none;
    }
