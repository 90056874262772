.ilicon-img-purple-16 {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/purple-16/sprite.svg);
}
.ilicon-img-access-report-purple-16 {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.ilicon-img-admin-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -32px 0;
}
.ilicon-img-admin-warning-1-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -64px 0;
}
.ilicon-img-acrobat-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -96px 0;
}
.ilicon-img-caret-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -128px 0;
}
.ilicon-img-caret-down-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -160px 0;
}
.ilicon-img-check-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -192px 0;
}
.ilicon-img-chevron-down-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -224px 0;
}
.ilicon-img-chevron-left-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -256px 0;
}
.ilicon-img-chevron-right-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -288px 0;
}
.ilicon-img-chevron-up-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -320px 0;
}
.ilicon-img-close2-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -352px 0;
}
.ilicon-img-filter-applied-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -384px 0;
}
.ilicon-img-cloud-upload-mobile-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -416px 0;
}
.ilicon-img-comments-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -448px 0;
}
.ilicon-img-confirm-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -480px 0;
}
.ilicon-img-copy-purple-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -32px;
}
.ilicon-img-single-line-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}
.ilicon-img-delete-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}
.ilicon-img-descend-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}
.ilicon-img-doc_move-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}
.ilicon-img-doc-add-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}
.ilicon-img-doc-extension-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}
.ilicon-img-document-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}
.ilicon-img-doc-updated-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}
.ilicon-img-doc-upload-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}
.ilicon-img-download-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}
.ilicon-img-download-doc-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}
.ilicon-img-downloaded-indicator-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}
.ilicon-img-download-offline-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}
.ilicon-img-edit-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}
.ilicon-img-eforms-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}
.ilicon-img-ellipsis-purple-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -64px;
}
.ilicon-img-excel-logo-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}
.ilicon-img-export-details-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}
.ilicon-img-hamburger-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}
.ilicon-img-help-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}
.ilicon-img-image-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}
.ilicon-img-logout-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}
.ilicon-img-mail-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}
.ilicon-img-minus-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}
.ilicon-img-move-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}
.ilicon-img-plus-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}
.ilicon-img-ppt-logo-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}
.ilicon-img-ilicon_picture-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}
.ilicon-img-reply-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}
.ilicon-img-search-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}
.ilicon-img-sync-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}
.ilicon-img-user-purple-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -96px;
}
.ilicon-img-word-logo-purple-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

