    .folder-icon {
        width: 36px;
        height: 36px;
        display: inline-block;
        font-size: 1.5em;
    }
    
    .listBox {
        position: relative;
        padding: 0px 20px;
        cursor: pointer;
    }
    
    .listBox:hover {
        color: rgba(85, 85, 85, 0.8);
    }
    bv 
    .listBox>h3 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 10px auto;
    }
    
    .listBox>h3>span:nth-child(2) {
        font-size: 0.9em;
    }
    
    @media (max-width: $mobile-max-width) {
        .page-header p {
            font-size: 1.5em;
        }
        .folder-icon {
            width: 30px;
            height: 30px;
            display: inline-block;
            font-size: 1.5em;
            margin: 5px;
        }
        .listBox>h3 {
            font-size: 1.5em;
        }
    }