 @media (max-width: $mobile-max-width) {
     .open {
         top: 30px;
     }
 }

 .openCase-box {
     height: 200px;
     width: 250px;
     background: nth($openCase-box, 1);
     color: nth($openCase-box, 2);
     padding: 15px;
     margin: 15px 10px;
     user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     display: inline-block;
 }

 .openCase-box>.title {
     font-size: 1.2em;
     display: block;
     height: 60px;
 }

 .openCase-box>.content {
     font-size: 5em;
 }

 .day {
     font-size: 0.25em;
     margin: auto 5px;
 }

 .nav {
     font-family: 'GillSansMTStd', 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', 'Arial', 'sans-serif';
 }

 .il-header-nav-tabs>li.active>a:after {
     background-color: nth($themeColor, 1);
     ;
 }

 .nav-tabs {
     border-bottom: 0;
 }

 .nav-tabs>li.active>a,
 .nav-tabs>li.active>a:hover,
 .nav-tabs>li.active>a:focus {
     color: nth($themeColor, 1);
     ;
 }

 .nav>li>a {
     color: $nav-a;
 }

 .caseCard {
     position: relative;
     border-bottom: 1px solid nth($themeColor, 2);
 }

 .caseCard,
 .caseCard-left,
 .caseCard-right {
     height: 75px;
     font-family: 'GillSansMTStd', 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', 'Arial', 'sans-serif';
 }

 .caseCard-left {
     background: nth($themeColor, 1);
     text-align: center;
     padding: 5px 0;
     position: absolute;
     left: 0;
     width: 5px;
     display: inline-block;
     border-bottom: 1px solid nth($themeColor, 2);
 }

 .caseCard-left-width{
     width: 25px;
 }

 .caseCard-right {
    padding: 5px;
    width: 100%;
 }

 .caseCard-right-width{
    padding-left: 25px;
    padding-right: 0;
}

.pc-progress-bar-icon{
    height: 16px;
    text-align: center;
}

.pc-caseCard-icon{
    display: inline-block;
    width: 100%;
    margin-bottom: 3px;
}

.mobile-progress{
    height: 5px;
    margin-bottom: 0px;
    background-color: #D2D2D2;
}


 .caseCard-left span,
 .caseCard-left img {
     font-size: 1.5em;
     color: $background-font;
     margin: 5px auto;
     position: absolute;
     left: 5px;
 }

 .caseCard-right>p>.pull-right>span {
     color: nth($themeColor, 1);
     ;
 }

 .caseCard-right>p>.pull-left>span {
     color: nth($themeColor, 1);
     ;
 }

 .jqx-grid-icon {
     margin: 4px;
     font-size: 1.2em;
     
 }

 .application-search-form-text {
     color: nth($themeColor, 1);
     ;
     border-radius: 0;
 }

 .il-header-nav-tabs>li>a {
     text-transform: none;
 }

 .font-overflow {
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
 }

 .caseCard-icon{
     margin-top: 5px;
     text-align: center;
 }

 .case-card-comments-icon{
     bottom: 0;
 }

 .search-box {
     width: 100%;
     padding: 10px;
     margin: 0;
     background: nth($themeColor, 2);
     position: relative;
 }

 .search-btn {
     cursor: pointer;
     display: table-cell;
     width: 1%;
     padding: 0 15px;
     margin: 0;
     vertical-align: middle;
     color: nth($themeColor, 1);
     ;
     font-size: 1.4em;
 }

 .page-header p {
     margin: 0;
 }

 .icon-box {
     width: 16px;
     height: 16px;
     background-repeat: no-repeat;
     display: inline-block;
     margin: 5px 2px;
     top: -5px;
     position: relative;
     cursor: pointer
 }

 .icon-list-box {
     width: 200px;
     display: inline-block;
 }

 .png-img {
     top: 0;
     margin: 5px;
     vertical-align: middle;
     margin-bottom: 10px;
 }

 .jqx-grid-cell-alt {
     background: nth($jqx-grid, 1);
 }

 .grid-row-font-bold {
     font-weight: bold;
 }

 .jqx-fill-state-pressed {
     color: nth($jqx-grid, 2);
     background-color: nth($jqx-grid, 3);
 }
 .analytics-value{
     min-height: 71px;
     min-width: 71px;
     display: inline-block;
 }
 .application-grid-tooltip-panel{
     margin-top:15px;
 }

 .input-clear-btn{
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    opacity: 0.2;
    z-index: 100;
}

.caseCard-icon-block{
    display: inline;
    margin-right: 16%;
}

.icon-list-box-span{
    vertical-align: top;
}

.clone-mortgage-validation-error {
    color: nth($themeColor, 1);
    margin-top: 0.5rem;
    margin-right: 1rem;
}