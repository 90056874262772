//Main colors
$primary: #E61E2D  #000000 #F9F9F9;
$secondary: #833C8C #00975F #F49619;

//Shades
$primary-light: lighten(nth($primary, 1), 30%) lighten(nth($primary, 2), 30%) lighten(nth($primary, 3), 30%);
$primary-lighter: lighten(nth($primary, 1), 60%) lighten(nth($primary, 2), 60%) lighten(nth($primary, 3), 60%);
$secondary-light: lighten(nth($secondary, 1), 30%) lighten(nth($secondary, 2), 30%) lighten(nth($secondary, 3), 30%);
$secondary-lighter: lighten(nth($secondary, 1), 60%) lighten(nth($secondary, 2), 60%) lighten(nth($secondary, 3), 60%);

$primary-dark: darken(nth($primary, 1), 10%) darken(nth($primary, 2), 10%) darken(nth($primary, 3), 10%);
$primary-darker: darken(nth($primary, 1), 80%) darken(nth($primary, 2), 80%) darken(nth($primary, 3), 80%);
$secondary-dark: darken(nth($secondary, 1), 10%) darken(nth($secondary, 2), 10%) darken(nth($secondary, 3), 10%);
$secondary-darker: darken(nth($secondary, 1), 80%) darken(nth($secondary, 2), 80%) darken(nth($secondary, 3), 80%);





///////////////////////////////
//OLD
$background:nth($primary, 1);
$fontColor:nth($primary, 1);
$headerColor: #E2E1E1 nth($primary, 2); 
$navbarColor:nth($primary, 1);
$logoUrl: '../images/Logo-res.png';
$modalHeader:nth($primary-light, 2);
//
$themeColor:nth($primary, 1) nth($primary-dark, 3);
//details
$dropbox:nth($secondary, 3);
$background-font:nth($primary, 3);
$btn-download:nth($secondary, 3) nth($primary, 3) nth($secondary, 3) nth($primary, 3);
$btn-approve:nth($secondary, 2) nth($primary, 3) nth($secondary, 2) nth($primary, 3);
$btn-reject: nth($secondary, 1) nth($primary, 3) nth($secondary, 1) nth($primary, 3);
$btn-delete:nth($primary, 1) nth($primary, 3) nth($primary, 1) nth($primary, 3);
$btn-download-dark:nth($secondary-dark, 3) nth($primary-dark, 3) nth($secondary-dark, 3) nth($primary-dark, 3);
$btn-approve-dark:nth($secondary-dark, 2) nth($primary-dark, 3) nth($secondary-dark, 2) nth($primary-dark, 3);
$btn-reject-dark: nth($secondary-dark, 1) nth($primary-dark, 3) nth($secondary-dark, 1) nth($primary-dark, 3);
$btn-delete-dark:nth($primary-dark, 1) nth($primary-dark, 3) nth($primary-dark, 1) nth($primary-dark, 3);
$check-green-icon:nth($secondary, 2);
$check-red-icon:nth($primary, 1);
$file-doc:nth($secondary, 1);
$received-box-span:nth($secondary, 1);
$message-title:nth($secondary, 1);
$header-right-label:nth($primary, 1);
$header-right-drop:nth($secondary, 3);
$message-title-span:nth($primary-light, 2);
$massage-content-font:nth($primary-lighter, 2);
$tint:rgba(51, 51, 51, 0.4);
//option
$optionCard:#E2E1E1 #000000 #f4f2f2;
//applications
$openCase-box:#939498 nth($primary, 3);
$jqx-grid:#f9f9f9 nth($primary, 3) nth($primary-dark, 3);
$nav-a:#aaa;
//navbar
$icon-bar:nth($primary, 2);
//body
$body-background:#f9f9f9;
$modal:nth($primary, 3);
$red-btn:nth($primary, 1) nth($primary, 3) nth($primary, 1) nth($primary, 3);
$btn-default:#848484 nth($primary, 3) #999 nth($primary, 3);
$grid-row-light-red:#d8d0d0;
$color-brown:nth($secondary, 3);
$auto-hide:rgba(0, 0, 0, 0.298039);

$mobile-min-width: 768px;
$mobile-max-width: 767px;