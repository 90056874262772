.ilicon-img-orange-16  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/orange-16/sprite.svg);
}
.ilicon-img-access-report-orange-16 {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.ilicon-img-admin-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -32px 0;
}
.ilicon-img-admin-warning-1-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -64px 0;
}
.ilicon-img-acrobat-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -96px 0;
}
.ilicon-img-caret-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -128px 0;
}
.ilicon-img-caret-down-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -160px 0;
}
.ilicon-img-check-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -192px 0;
}
.ilicon-img-chevron-down-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -224px 0;
}
.ilicon-img-chevron-left-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -256px 0;
}
.ilicon-img-chevron-right-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -288px 0;
}
.ilicon-img-chevron-up-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -320px 0;
}
.ilicon-img-close2-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -352px 0;
}
.ilicon-img-filter-applied-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -384px 0;
}
.ilicon-img-cloud-upload-mobile-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -416px 0;
}
.ilicon-img-comments-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -448px 0;
}
.ilicon-img-confirm-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -480px 0;
}
.ilicon-img-copy-orange-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -32px;
}
.ilicon-img-single-line-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}
.ilicon-img-delete-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}
.ilicon-img-descend-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}
.ilicon-img-doc_move-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}
.ilicon-img-doc-add-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}
.ilicon-img-doc-extension-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}
.ilicon-img-document-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}
.ilicon-img-doc-updated-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}
.ilicon-img-doc-upload-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}
.ilicon-img-download-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}
.ilicon-img-download-doc-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}
.ilicon-img-downloaded-indicator-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}
.ilicon-img-download-offline-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}
.ilicon-img-edit-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}
.ilicon-img-eforms-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}
.ilicon-img-ellipsis-orange-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -64px;
}
.ilicon-img-excel-logo-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}
.ilicon-img-export-details-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}
.ilicon-img-hamburger-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}
.ilicon-img-help-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}
.ilicon-img-image-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}
.ilicon-img-logout-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}
.ilicon-img-mail-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}
.ilicon-img-minus-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}
.ilicon-img-move-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}
.ilicon-img-plus-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}
.ilicon-img-ppt-logo-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}
.ilicon-img-ilicon_picture-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}
.ilicon-img-reply-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}
.ilicon-img-search-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}
.ilicon-img-sync-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}
.ilicon-img-user-orange-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -96px;
}
.ilicon-img-word-logo-orange-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

