.ilicon-img-extended-16  {
  display: inline-block;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}

.ilicon-img-extended-file-type-adobe-16  {
  background-image: url(../images/ilicon-extended-16/file-type-adobe.png);
}

.ilicon-img-extended-file-type-excel-16  {
  background-image: url(../images/ilicon-extended-16/file-type-excel.png);
}

.ilicon-img-extended-file-type-file-16  {
  background-image: url(../images/ilicon-extended-16/file-type-file.png);
}

.ilicon-img-extended-file-type-image-16  {
  background-image: url(../images/ilicon-extended-16/file-type-image.png);
}

.ilicon-img-extended-file-type-ppt-16  {
  background-image: url(../images/ilicon-extended-16/file-type-ppt.png);
}

.ilicon-img-extended-file-type-word-16  {
  background-image: url(../images/ilicon-extended-16/file-type-word.png);
}

.ilicon-img-extended-file-type-zip-16  {
  background-image: url(../images/ilicon-extended-16/file-type-zip.png);
}



.ilicon-img-extended-valuation-received-red-16  {
  background-image: url(../images/ilicon-extended-16/valuation-received-red.svg);
}

.ilicon-img-extended-valuation-received-white-16  {
  background-image: url(../images/ilicon-extended-16/valuation-received-white.svg);
}

.ilicon-img-extended-valuation-received-grey-16  {
  background-image: url(../images/ilicon-extended-16/valuation-received-grey-light.svg);
}

.ilicon-img-extended-valuation-received-green-16  {
  background-image: url(../images/ilicon-extended-16/valuation-received-green.svg);
}

.ilicon-img-extended-valuation-received-purple-16  {
  background-image: url(../images/ilicon-extended-16/valuation-received-purple.svg);
}



.ilicon-img-extended-offer-issued-green-16  {
  background-image: url(../images/ilicon-extended-16/offer-issued-green.svg);
}

.ilicon-img-extended-offer-issued-grey-16  {
  background-image: url(../images/ilicon-extended-16/offer-issued-grey-light.svg);
}


.ilicon-img-extended-funds-requested-grey-16  {
  background-image: url(../images/ilicon-extended-16/funds-requested-grey-light.svg);
}

.ilicon-img-extended-funds-requested-orange-16  {
  background-image: url(../images/ilicon-extended-16/funds-requested-orange.svg);
}



.ilicon-img-extended-mortgage-completed-grey-16  {
  background-image: url(../images/ilicon-extended-16/mortgage-completed-grey-light.svg);
}

.ilicon-img-extended-mortgage-completed-orange-16  {
  background-image: url(../images/ilicon-extended-16/mortgage-completed-orange.svg);
}