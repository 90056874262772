.ilicon-img-black-32  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/black-32/sprite.svg);
}
.ilicon-img-access-report-black-32 {
  width: 32px;
  height: 32px;
  background-position: 0 0;
}
.ilicon-img-admin-black-32 {
  width: 32px;
  height: 32px;
  background-position: -48px 0;
}
.ilicon-img-admin-warning-1-black-32 {
  width: 32px;
  height: 32px;
  background-position: -96px 0;
}
.ilicon-img-acrobat-black-32 {
  width: 32px;
  height: 32px;
  background-position: -144px 0;
}
.ilicon-img-caret-black-32 {
  width: 32px;
  height: 32px;
  background-position: -192px 0;
}
.ilicon-img-caret-down-black-32 {
  width: 32px;
  height: 32px;
  background-position: -240px 0;
}
.ilicon-img-check-black-32 {
  width: 32px;
  height: 32px;
  background-position: -288px 0;
}
.ilicon-img-chevron-down-black-32 {
  width: 32px;
  height: 32px;
  background-position: -336px 0;
}
.ilicon-img-chevron-left-black-32 {
  width: 32px;
  height: 32px;
  background-position: -384px 0;
}
.ilicon-img-chevron-right-black-32 {
  width: 32px;
  height: 32px;
  background-position: -432px 0;
}
.ilicon-img-chevron-up-black-32 {
  width: 32px;
  height: 32px;
  background-position: -480px 0;
}
.ilicon-img-close2-black-32 {
  width: 32px;
  height: 32px;
  background-position: -528px 0;
}
.ilicon-img-filter-applied-black-32 {
  width: 32px;
  height: 32px;
  background-position: -576px 0;
}
.ilicon-img-cloud-upload-mobile-black-32 {
  width: 32px;
  height: 32px;
  background-position: -624px 0;
}
.ilicon-img-comments-black-32 {
  width: 32px;
  height: 32px;
  background-position: -672px 0;
}
.ilicon-img-confirm-black-32 {
  width: 32px;
  height: 32px;
  background-position: -720px 0;
}
.ilicon-img-copy-black-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -48px;
}
.ilicon-img-single-line-black-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -48px;
}
.ilicon-img-delete-black-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -48px;
}
.ilicon-img-descend-black-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -48px;
}
.ilicon-img-doc_move-black-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -48px;
}
.ilicon-img-doc-add-black-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -48px;
}
.ilicon-img-doc-extension-black-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -48px;
}
.ilicon-img-document-black-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -48px;
}
.ilicon-img-doc-updated-black-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -48px;
}
.ilicon-img-doc-upload-black-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -48px;
}
.ilicon-img-download-black-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -48px;
}
.ilicon-img-download-doc-black-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -48px;
}
.ilicon-img-downloaded-indicator-black-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -48px;
}
.ilicon-img-download-offline-black-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -48px;
}
.ilicon-img-edit-black-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -48px;
}
.ilicon-img-eforms-black-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -48px;
}
.ilicon-img-ellipsis-black-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -96px;
}
.ilicon-img-excel-logo-black-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -96px;
}
.ilicon-img-export-details-black-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -96px;
}
.ilicon-img-hamburger-black-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -96px;
}
.ilicon-img-help-black-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -96px;
}
.ilicon-img-image-black-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -96px;
}
.ilicon-img-logout-black-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -96px;
}
.ilicon-img-mail-black-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -96px;
}
.ilicon-img-minus-black-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -96px;
}
.ilicon-img-move-black-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -96px;
}
.ilicon-img-plus-black-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -96px;
}
.ilicon-img-ppt-logo-black-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -96px;
}
.ilicon-img-ilicon_picture-black-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -96px;
}
.ilicon-img-reply-black-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -96px;
}
.ilicon-img-search-black-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -96px;
}
.ilicon-img-sync-black-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -96px;
}
.ilicon-img-user-black-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -144px;
}
.ilicon-img-word-logo-black-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -144px;
}

