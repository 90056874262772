.ilicon-img-green-16  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/green-16/sprite.svg);
}
.ilicon-img-access-report-green-16 {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.ilicon-img-admin-green-16 {
  width: 16px;
  height: 16px;
  background-position: -32px 0;
}
.ilicon-img-admin-warning-1-green-16 {
  width: 16px;
  height: 16px;
  background-position: -64px 0;
}
.ilicon-img-acrobat-green-16 {
  width: 16px;
  height: 16px;
  background-position: -96px 0;
}
.ilicon-img-caret-green-16 {
  width: 16px;
  height: 16px;
  background-position: -128px 0;
}
.ilicon-img-caret-down-green-16 {
  width: 16px;
  height: 16px;
  background-position: -160px 0;
}
.ilicon-img-check-green-16 {
  width: 16px;
  height: 16px;
  background-position: -192px 0;
}
.ilicon-img-chevron-down-green-16 {
  width: 16px;
  height: 16px;
  background-position: -224px 0;
}
.ilicon-img-chevron-left-green-16 {
  width: 16px;
  height: 16px;
  background-position: -256px 0;
}
.ilicon-img-chevron-right-green-16 {
  width: 16px;
  height: 16px;
  background-position: -288px 0;
}
.ilicon-img-chevron-up-green-16 {
  width: 16px;
  height: 16px;
  background-position: -320px 0;
}
.ilicon-img-close2-green-16 {
  width: 16px;
  height: 16px;
  background-position: -352px 0;
}
.ilicon-img-filter-applied-green-16 {
  width: 16px;
  height: 16px;
  background-position: -384px 0;
}
.ilicon-img-cloud-upload-mobile-green-16 {
  width: 16px;
  height: 16px;
  background-position: -416px 0;
}
.ilicon-img-comments-green-16 {
  width: 16px;
  height: 16px;
  background-position: -448px 0;
}
.ilicon-img-confirm-green-16 {
  width: 16px;
  height: 16px;
  background-position: -480px 0;
}
.ilicon-img-copy-green-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -32px;
}
.ilicon-img-single-line-green-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}
.ilicon-img-delete-green-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}
.ilicon-img-descend-green-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}
.ilicon-img-doc_move-green-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}
.ilicon-img-doc-add-green-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}
.ilicon-img-doc-extension-green-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}
.ilicon-img-document-green-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}
.ilicon-img-doc-updated-green-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}
.ilicon-img-doc-upload-green-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}
.ilicon-img-download-green-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}
.ilicon-img-download-doc-green-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}
.ilicon-img-downloaded-indicator-green-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}
.ilicon-img-download-offline-green-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}
.ilicon-img-edit-green-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}
.ilicon-img-eforms-green-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}
.ilicon-img-ellipsis-green-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -64px;
}
.ilicon-img-excel-logo-green-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}
.ilicon-img-export-details-green-16 {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}
.ilicon-img-hamburger-green-16 {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}
.ilicon-img-help-green-16 {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}
.ilicon-img-image-green-16 {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}
.ilicon-img-logout-green-16 {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}
.ilicon-img-mail-green-16 {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}
.ilicon-img-minus-green-16 {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}
.ilicon-img-move-green-16 {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}
.ilicon-img-plus-green-16 {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}
.ilicon-img-ppt-logo-green-16 {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}
.ilicon-img-ilicon_picture-green-16 {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}
.ilicon-img-reply-green-16 {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}
.ilicon-img-search-green-16 {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}
.ilicon-img-sync-green-16 {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}
.ilicon-img-user-green-16 {
  width: 16px;
  height: 16px;
  background-position: 0 -96px;
}
.ilicon-img-word-logo-green-16 {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

