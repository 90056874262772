    @import './variables';
    @import '../app/layout/navbar';
    @import '../app/layout/header';
    @import '../app/layout/templates';
    @import '../app/options/options';
    @import '../app/applications/applications';
    @import '../app/applications-details/applications-details';
    @import '../app/applications-new/applications-new'; //Components
    @import '../app/login/login.scss';
    @import '../app/cpf/cpf.scss';
    @import '../app/cpf-print/cpf-print.scss';
    @import '../app/cpf-avm/cpf-avm.scss';
    @import '../app/shared/modal/modal-workspace-entry-security/modal-workspace-entry-security.scss';

    //Custom icons
    @import '../images/ilicon/black-16/sprite'; 
    @import '../images/ilicon/black-32/sprite'; 
    @import '../images/ilicon/black-64/sprite'; 
    @import '../images/ilicon/green-16/sprite'; 
    @import '../images/ilicon/green-32/sprite'; 
    @import '../images/ilicon/grey-16/sprite'; 
    @import '../images/ilicon/orange-16/sprite';
    @import '../images/ilicon/purple-16/sprite'; 
    @import '../images/ilicon/purple-32/sprite'; 
    @import '../images/ilicon/red-16/sprite'; 
    @import '../images/ilicon/red-32/sprite'; 
    @import '../images/ilicon/red-128/sprite'; 
    @import '../images/ilicon/white-16/sprite';  
    @import '../images/ilicon/white-32/sprite';  
    @import '../images/ilicon-extended-16/sprite'; 
    @import '../images/ilicon-extended-32/sprite'; 

    input::-ms-clear{display:none;}
    
    

    body {
        background: $body-background;
        font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
        -ms-overflow-style: scrollbar;
    }

    html,
    body {
        height: 100%;
    }

    // .modal.fade .modal-dialog {
    //     transform: translate(0, 5%);
    // }

    .modal .modal-body {
        background-color: $modal;
        min-height: 220px;
    }

    #container {
        min-height: 100%;
        margin-bottom: -60px;
        position: relative;
    }

    .isdisplay {
        display: none!important;
    }

    #footer {
        height: 60px;
        width: 100%;
        position: relative;
    }

    .clearfooter {
        height: 60px;
        clear: both;
    }

    label,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .control-label,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
    }
    /* login body css start*/

    .login-clear {
        margin: 0!important;
    }

    .clear-box {
        position: relative;
        min-height: 50px;
        margin: 0;
        display: block;
    }
    /*login body css end*/

    
    @media (min-width: $mobile-min-width) {
        .il-body-content {
            margin-top: 0;
            margin-left: 64px;
            padding-bottom: 45px;
            padding-right: 0px !important;
            // Add 80px for the vertical nav bar and browser window scroll bar
            min-width: 800px; 
        }
        .il-body-login {
            margin-top: 0;
            margin-left: 0;
            padding-bottom: 45px;
        }
        .wrapper {
            width: 450px;
        }
    }

    @media (max-width: $mobile-max-width) {
        .home-body {
            top: 74px;
            padding-bottom: 75px!important;
        }
        .wrapper {
            width: 100%;
            padding: 0 15px;
        }
        .tooltip
        {
            display: none !important;
        }
    } //page-header
    .page-header {
        border-bottom: 2px solid nth($themeColor, 2);
        ;
        margin-top: 0;
    }

    .page-header>p {
        font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
        font-weight: 500;
        color: nth($themeColor, 1);
        font-size: 2em;
    }

    .page-header .header-left {
        display: inline-block;
        font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
        font-weight: 500;
        color: nth($themeColor, 1);
        font-size: 1.25em;
    }

    .view-header {
        border-bottom: 2px solid nth($themeColor, 2);
        ;
        padding: 5px 0;
        margin-bottom: 10px;
        padding-bottom: 15px;
    }

    .view-header>p {
        font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
        color: nth($themeColor, 1);
        font-size: 1.25em;
        padding: 0;
        margin: 0;
    } //clear
    .clear-margin {
        margin: 0;
    }

    .clear-padding {
        padding: 0;
    }

    .auto-hide {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        background-color: $auto-hide;
    }

    .application-search-form-text {
        color: $navbarColor;
    }

    input.application-search-form-text {
        color: $navbarColor;
    }

    .jqx-grid-column-header {
        text-transform: none
    }

    .jqx-grid-sortasc-icon {
        margin-left: 4px;
    }

    .jqx-grid-sortdesc-icon {
        margin-left: 4px;
    }

    .jqx-grid-sortremove-icon {
        margin-left: 4px;
    }

    .check-btn {
        width: 30px;
        height: 30px;
        border: 2px solid nth($themeColor, 2);
        border-radius: 30px;
        position: absolute;
    }

    .check-icon {
        position: absolute;
        top: 5px;
        left: 5px;
        color: $check-green-icon;
        font-size: 18px;
    }

    .check-icon-red {
        position: absolute;
        top: 5px;
        left: 5px;
        color: $check-red-icon;
        font-size: 18px;
    }

    .color-red {
        color: nth($themeColor, 1);
    }

    .color-brown {
        color: $color-brown;
    }

    .default-cursor {
        cursor: default;
    }

    .pointer-cursor {
        cursor: pointer;
    }

    .red-btn {
        color: $background-font;
        background: nth($red-btn, 1);
        border-radius: 50px;
        float: right;
        text-align: left;
        padding: 4px 15px;
    }

    .required-doc-value {
        font-size: 1.25em;
        margin: 5px 0;
        display:inline-flex;
        padding-left: 0;
    }

    .mobile-req-doc-value{
        width: 100%;
    }

    .modal-submit {
        min-width: 120px;
        border-radius: 40px;
        background: nth($red-btn, 1);
        color: $background-font;
    }

    .btn {
        min-width: 80px;
        border-radius: 20px;
        margin: auto 5px
    }


    .btn-default {
        color: nth($btn-default, 2);
        background-color: nth($btn-default, 1);
        border-color: nth($btn-default, 1);
    }

    .btn-default:hover,
    .btn-default:hover,
    .btn-default:active,
    .btn-default.focus {
        color: nth($btn-default, 4);
        background-color: nth($btn-default, 3);
        border-color: nth($btn-default, 3);
    }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle.focus{
        color: #dddddd;
        background-color: #848484;
        border-color: #848484;
    }

    .btn-red {
        color: nth($primary, 3);
        text-decoration: none;
        border-color:nth($primary, 1);
        background: nth($primary, 1);
    }

    .btn-red:hover,
    .btn-red:active,
    .btn-red.focus {
        color: nth($primary-dark, 3);
        background-color: nth($primary-dark, 1);
        border-color:nth($primary-dark, 1);
        background: nth($primary-dark, 1);
    }

    .modal-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .tooltip-inner {
        max-width: 100%;
        width: 100%;
    }

    .grid-row-light-red {
        background-color: $grid-row-light-red;
    }

    span.file-delete-button {
        font-size: 10px;
        cursor: pointer;
        margin: 0px 5px 5px 5px;
        color: nth($themeColor, 1);
        display: inline-block;
        float:right;
    }
    .btn-link{
        color: nth($secondary, 1);
    }

    .message-thread{
        margin-top: -10px;
        padding-left: 40px;
        padding-right: 0px!important;
    }
    *{
        outline:none
    }
    tags-input .tags .tag-item {
        margin: 3px ;
        padding: 0 3px;
        display: inline-block;
        font-size: 12px;
        height: 21px;
        line-height:21px;
    }

   .text-trim {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 17.5px;
        margin-left: 5px;
        // display: inline-block;
        vertical-align: middle;
    }

    .form-control:focus{
        border-color: nth($secondary, 1);
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px nth($secondary-lighter, 1);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px nth($secondary-lighter, 1);
    }

    .vertical-align-content {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
    }

    .message-unread{
        font-weight: bold;
    }
    .message-item{
        font-size:18px;
        margin-top:15px;
    }
    .form-group .help-block{
        margin-bottom: 0px;
    }
    #btn_toggleNavigation{
        border: 0;
    }
    
    .navbar-nav{
        // margin: 0 -15px;
    }
    .document-virus-found-alert-message{
        position: fixed;
        bottom: 0;
        right: 0;
        opacity: 0.9
    }
    
    .min-navbar-width{
        min-width: 64px;
    }