 ul {
     list-style: none;
     padding: 0;
 }

 .view-header {
     padding: 5px 0;
 }

 .view-header-option {
     color: $background-font;
     background: nth($themeColor, 1);
     border-radius: 50px;
     min-width: 100px;
     float: right;
     text-align: left;
     padding: 4px 15px;
 }

 .view-header-option>span {
     font-size: 1.25em;
 }

 .view-header-option>p {
     display: inline;
     margin: 0px 10px;
     vertical-align: middle;
     font-size: 15px;
 }

 .view-header-switch {
     display: -webkit-inline-box;
     float: right;
     cursor: pointer;
 }

 .check-box {
     margin: 15px 0;
     font-size: 1.25em;
     position: relative;
 }

 .check-box label {
     font-weight: normal;
 }

 .check-box .check-box-label {
     min-height: 15px;
     padding-left: 20px;
     font-weight: normal;
     vertical-align: middle;
     padding-top: 5px;
 }

 .check-content {
     padding-left: 40px;
     margin-top: 5px;
 }

 .received-box span {
     font-size: 1.25em;
     color: $received-box-span;
 }

 .success-check {
     font-size: 1.25em;
     color: $check-green-icon;
     display: inline-block;
     margin: 5px;
 }

 .error-check {
     font-size: 1.25em;
     color: $check-red-icon;
     display: inline-block;
     margin: 5px;
 }

 .drop-box {
     min-height: 160px;
     border: solid 2px $dropbox;
     border-style: dashed;
     margin: 20px 0;
 }

 .dragover {
     border: solid 2px $dropbox;
     border-style: dashed;
 }

 .file-icon {
     display: inline-block;
     font-size: 1.25em;
 }

 .file-set-font {
     font-size: 1.25em;
     width: 100%;
     padding: 0;
     font-weight: normal;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }

 .process-box:after {
     content: "";
     display: block;
     position: absolute;
     top: 28px;
     width: 0px;
     height: 26px;
     border: 1px solid transparent;
     right: 12px;
     border-color: nth($themeColor, 2);
 }

 ul li:last-child div.check-box label.process-box:after {
     display: none;
 }

 .process-index {
     position: absolute;
     left: 8px;
     top: 2px;
 }

 .process-unfinished {
     color: nth($themeColor, 2);
 }

 .process-completed {
     background: nth($themeColor, 2);
     color: $background-font;
 }

 .process-continued {
     background: nth($themeColor, 1);
     border: 2px solid nth($themeColor, 1) !important;
     color: $background-font;
 }

 .message-box {
     display: table;
     margin: 5px 0;
 }

 .message-title {
     color: $message-title;
 }

 .message-title span {
     color: $message-title-span;
 }

 .message-title h3 {
     margin: 0;
     font-size: 1.5em;
 }

 .massage-content h4 {
     color: $massage-content-font;
     padding: 0px 25px;
 }

 .message-title .massage-date {
     color: nth($themeColor, 1);
     font-size: 18px;
 }

 .message-title,
 .massage-content {
     display: -webkit-inline-box;
     white-space: pre-line;
 }

 .font-set {
     margin-left: 20px;
 }

 .drop-prompt {
     margin: 50px 0;
     position: absolute;
     top: 0;
     left: 0;
 }

 .received-span {
     display: inline-block
 }

 .received-block {
     display: inline-grid;
 }

 .received-div {
     font-size: 0.8em;
     padding-left: 15px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }

 .image-gallary i {
     color: $message-title;
 }

 .progress-success {
     width: 100%;
     font-size: 9em;
     color: nth($themeColor, 1);
 }

 .progress-font {
     font-size: 20px;
 }

 .progress-pro {
     background-color: nth($themeColor, 1);
 }

 .header-right {
     font-size: 1.25em;
     float: right;
     line-height: 40px;
     padding: 0 15px;
 }

 .header-right-drop {
     color: $header-right-drop;
     display: inline-block;
 }

 .header-right-label {
     color: $header-right-label;
     display: inline-block;
 }

 .operations-modal-left button {
     width: 110px;
     margin: 3px 0;
     font-size: 1em;
 }

 .operations-modal-left p {
     display: inline-block;
     margin: 0;
     vertical-align: middle;
 }

 .operations-modal-left span {
     display: inline-block;
     vertical-align: middle;
 }

 .btn-download {
     color: nth($btn-download, 2);
     background: nth($btn-download, 1);
     text-align: left;
     display: block;
 }

 .btn-download:hover,
 .btn-download:active {
     color: nth($btn-download-dark, 4);
     vertical-align: middle;
     background: nth($btn-download-dark, 3);
     text-align: left;
 }

 .btn-approve {
     color: nth($btn-approve, 2);
     vertical-align: middle;
     background: nth($btn-approve, 1);
     text-align: left;
 }

 .btn-approve:hover,
 .btn-approve:active {
     color: nth($btn-approve-dark, 4);
     vertical-align: middle;
     background: nth($btn-approve-dark, 3);
     text-align: left;
 }

 .btn-reject {
     color: nth($btn-reject, 2);
     vertical-align: middle;
     background: nth($btn-reject, 1);
     text-align: left;
 }

 .btn-reject:hover,
 .btn-reject:active {
     color: nth($btn-reject-dark, 4);
     vertical-align: middle;
     background: nth($btn-reject-dark, 3);
     text-align: left;
 }

 .btn-delete {
     color: nth($btn-delete, 2);
     vertical-align: middle;
     background: nth($btn-delete, 1);
     text-align: left;
 }

 .btn-delete:hover,
 .btn-delete:active {
     color: nth($btn-delete-dark, 4);
     vertical-align: middle;
     background: nth($btn-delete-dark, 3);
     text-align: left;
 }

 .btn-history {
     text-align: left;
 }

 .icon-edit {
     font-size: 12px;
     vertical-align: top;
     cursor: pointer;
     margin-top: 8px;
 }

 .header-edit-input {
     vertical-align: text-bottom;
     display: inline-block;
     width: auto;
 }

 .check-green-icon {
     vertical-align: text-top;
     color: $check-green-icon;
     cursor: pointer;
     vertical-align: middle;
 }

 .check-red-icon {
     vertical-align: text-top;
     color: $check-red-icon;
     cursor: pointer;
     vertical-align: middle;
 }

 .form-label {
     font-size: 1.3em;
 }

 .form-check-green-icon {
     font-size: 1.25em;
     vertical-align: super;
     cursor: pointer;
     color: $check-green-icon;
     margin-left: 5px;
 }

 .form-check-red-icon {
     font-size: 1.25em;
     vertical-align: super;
     cursor: pointer;
     color: $check-red-icon;
     margin-left: 5px;
 }

 .form-icon-edit {
     font-size: 1em;
     vertical-align: top;
     cursor: pointer;
     margin-top: 5px;
 }

 .save-btn {
     background: nth($themeColor, 1);
     color: $background-font;
     border-radius: 20px;
 }

 .default-cursor {
     cursor: default;
 }

 .pointer-cursor {
     cursor: pointer;
 }

 .last-update-title {
     padding: 0 15px;
 }

 .form-padding {
     padding: 0 30px;
 }

 .file-upload-broker {
     font-size: 1.2em;
 }

 .margin-clear {
     margin: 0;
 }

 .require-doc-mobile {
     margin: 0 15px;
 }

 .require-doc-mobile i {
     font-size: 2.5em;
     color: $file-doc;
     white-space: nowrap;
 }

 .require-doc-mobile-label {
     font-size: 1.25em;
     vertical-align: top;
     margin-left: 10px;
     display: inline-block;
     width: 90%;
 }

 .file-upload-broker i {
     color: $file-doc;
     margin-right: 5px;
 }

 .file-upload-broker .img-warning {
     display: inline-table;
 }

 .file-upload-broker-title {
     float: left;
 }

 .file-upload-broker-label {
     width: 100%;
     margin: 5px 0;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }

 label.tags-item {
     font-size: 12px;
     height: 12px;
     margin: 1px;
     padding: 4px 8px;
     border-radius: 4px;
 }

 .tags-content {
     width: 100%
 }

 tags-input .tags .tag-item {
     background: nth($themeColor, 1);
     border: 1px solid nth($themeColor, 1);
     font-family: 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', Arial, sans-serif;
 }

 tags-input .tags .tag-item .remove-button {
     color: $background-font;
 }

 .tags-content-label {
     display: table;
     background: nth($themeColor, 1);
     border: 1px solid nth($themeColor, 1);
     color: $background-font;
     padding: 3px;
     margin-right: 3px;
     border-radius: 5px;
     font-size: 12px;
     margin-top: 3px;
 }

 .requiredDocumentExtend-custom-label:hover {
     text-decoration: underline;
 }

 .tags-icon {
     font-size: 10px;
     font-weight: bold;
     margin-left: 5px;
     cursor: pointer;
 }

 .dialog-box {
     vertical-align: bottom;
     display: inline-block;
     height: 40px;
     line-height: 45px;
     margin: 0 10px;
 }

 .form-display {
     float: left;
 }

 .control-label {
     font-size: 1.25em;
 }

 .reply-message-modal {
     border-radius: 0;
     top: 15%;
 }

 /*modal start*/

 .modal {
     font-family: 'GillSansMTStd', 'Myriad Pro', 'Myriad Web Pro', 'Myriad Web', 'Segoe UI', 'Calibri', 'Arial', 'sans-serif';
 }

 .modal-submit {
     min-width: 120px;
     border-radius: 40px;
     background: nth($themeColor, 1);
     color: $background-font;
 }

 .modal-textarea {
     width: 100%;
     min-height: 100px;
     border-radius: 5px;
 }

 .icon-box p {
     display: block;
     font-size: 2em;
 }

 .modal-icon {
     font-size: 6em;
     border: 10px solid;
     border-radius: 100px;
     padding: 45px;
     color: nth($themeColor, 1);
 }

 .modal-footer {
     border-radius: 0 0 5px 5px;
 }

 /*modal end*/

 /*mobile start*/

 .mobile-detail-title {
     background: nth($themeColor, 1);
     color: $background-font;
     font-size: 1.2em;
     padding: 10px;
     display: inline-flex;
     width: 100%;
 }

 .mobile-detail-title-block {
     width: 50%;
     padding-left: 15px;
     float: left;
 }

 .mobile-detail-title-block {
     width: 50%;
 }

 .mobile-detail-title-float {
     width: 50%;
     float: left;
 }

 .view-header-mobile {
     padding: 5px;
     background: nth($themeColor, 1);
     color: $background-font;
     margin: 10px 0;
     font-size: 1.25em;
 }

 .view-header-mobile p {
     display: inline-block;
     margin: 0;
 }

 .view-header-switch-mobile {
     float: right;
     cursor: pointer;
 }

 .view-body-reply-mobile {
     float: right;
     font-size: 1.3em;
     cursor: pointer;
     display: inline-grid;
     color: #E61E2D;
 }

 /*mobile end*/

 @media (min-width: $mobile-min-width) {
     .form-horizontal .control-label {
         padding: 0;
     }
     .received-box,
     .processed-box {
         margin: 8px 0;
         font-size: 1.25em;
         display: inline-flex;
         height: 45px;
     }
     .received-box label,
     .processed-box label {
         min-height: 15px;
         padding-left: 5px;
         margin-bottom: 0;
         font-weight: normal;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         height: 40px;
         line-height: 40px;
         width: 80%;
     }
 }

 @media(max-width: $mobile-max-width) {
     .font-set {
         width: 180px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
     }
     .message-title h3 {
         margin: 0;
         font-size: 1.25em;
     }
     .success-check {
         display: inline-block;
         font-size: 1em;
         margin: 5px;
     }
     .error-check {
         display: inline-block;
         font-size: 1em;
         margin: 5px;
     }
     .received-box,
     .processed-box {
         margin: 8px 0;
         font-size: 1.25em;
         display: inline-flex;
     }
     .view-header-mobile {
         font-size: 1.25em;
     }
     .require-doc-mobile i {
         font-size: 1.25em !important;
     }
     .received-box label,
     .processed-box label {
         min-height: 15px;
         padding-left: 5px;
         margin-bottom: 0;
         font-weight: normal;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
     }
 }

 .processed-text-trim {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     font-size: 17.5px;
     margin-left: 15px;
     vertical-align: middle;
     width: 100%;
 }

 .jqx-grid-cell-selected {
     background-color: nth($primary-lighter, 2);
     cursor: pointer;
 }

 .jqx-grid-cell-hover {
     background-color: nth($primary-dark, 3);
     cursor: pointer;
 }

 .custom-value {
     display: inline-flex;
 }

 .custom-value-content {
     display: inline-table;
 }

 .req-doc-extend {
     width: 80%;
     display: inline;
 }

 .req-doc-extend-error {
     width: 80%;
     display: inline;
     border: 1px solid red;
 }

 .req-doc-extend-error.form-control:focus {
     border: 1px solid red;
 }

 .border-char-no-error {
     border: 1px solid red;
 }

 .border-char-has-error {
     border: 1px solid red;
 }

 .border-char-has-error.form-control:focus {
     border: 1px solid red;
 }

 .char-has-error {
     color: red;
 }

 .char-no-error {
     color: #000;
 }

 .required-documents-notices {
     color: nth($primary, 1);
     font-size: 1.25em;
     position: absolute;
     bottom: 0;
     left: 0;
     width: 100%;
 }

 .mobile-save-btn {
     float: right;
     font-size: 1.3em;
     padding: 5px 15px;
 }

 .document-status-text-Accepted {
     margin: 3px 0;
     font-size: 18px;
     color: #00975F
 }

 .document-status-text-Rejected {
     margin: 3px 0;
     font-size: 18px;
     display: inline-block;
     color: nth($primary, 1)
 }

 .img-btn-vertical-middle {
     vertical-align: middle;
 }

 .processed-check-icon-div {
     float: left;
     width: 32px;
     height: 32px;
 }

 md-autocomplete md-autocomplete-wrap {
     display: -ms-flexbox;
 }

 .secure-message-create-by {
     color: nth($secondary, 3);
 }

 .new-message-icon {
     display: inline-block;
     background: red;
     border-radius: 50%;
     width: 5px;
     height: 5px;
     vertical-align: middle;
 }

 .file-progress-filename {
     margin-right: 5px;
     font-size: 15px;
 }

 .applicant-char {
     display: inline;
     font-size: 20px;
     vertical-align: super;
 }

 @media (min-width: 768px) {
     .clear-button-box {
         display: inline-block;
         float: initial;
     }
 }

 @media (min-width: 992px) {
     .clear-button-box {
         display: block;
         float: right;
     }
 }