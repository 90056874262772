    .check-box {
        margin: 10px 0;
        font-size: 1.25em;
        position: relative;
    }

    .check-box .check-box-label {
        min-height: 15px;
        padding-left: 20px;
        font-weight: normal;
        vertical-align: middle;
        padding-top: 5px;
    }

    /*upload*/

    .drop-box {
        min-height: 280px;
        border: solid 2px $dropbox;
        border-style: dashed;
        margin: 20px 0;
        position: relative;
    }

    .dragover {
        border: solid 2px $dropbox;
        border-style: dashed;
    }

    .file-icon {
        display: inline-block;
        font-size: 1.5em;
        margin: 5px;
    }

    .file-set-font {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    /* mobile*/

    .mobile-detail-bg {
        background: nth($themeColor, 1);
    }

    .mobile-detail-title {
        color: $background-font;
        font-size: 1.2em;
    }

    .mobile-detail-title-block {
        display: inline-block
    }

    .mobile-detail-title-float {
        float: right;
    }

    .mobile-detail-status-bg {
        background: nth($themeColor, 2);
    }

    .mobile-detail-status {
        padding: 5px;
        font-size: 1.3em;
        text-align: center;
    }

    .view-header-mobile {
        padding: 5px;
        background: nth($themeColor, 1);
        color: $background-font;
        margin: 10px 0;
        font-size: 1.3em;
    }

    .mobile-new-detail-label {
        padding-left: 0;
        margin-top: 5px;
        text-align: right;
    }

    .mobile-new-detail-input {
        padding-left: 0;
        line-height: 28px;
    }

    .mobile-new-detail-doc {
        font-size: 1.3em;
        margin: 10px 0;
    }

    .mobile-new-detail-doc>span {
        font-size: 1.2em;
    }

    .font-set {
        margin-left: 20px;
    }

    .drop-prompt-new {
        display: block;
        text-align: center;
        position: absolute;
        left: 0;
        margin: 50px 0;
        padding: 10px;
    }


    .progress-success {
        width: 100%;
        font-size: 9em;
        color: nth($themeColor, 1);
    }

    .view-btn {
        background: transparent;
        border: 2px solid;
        padding: 2px;
    }

    .tint {
        color: $tint;
    }

    .file-box {
        font-size: 1.5em;
        margin-top: 5px;
    }

    .file-box-content {
        font-size: 16px;
        display: flex;
    }

    .filedropcontent{
        display: block;
    }