.ilicon-img-extended-32  {
  display: inline-block;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
}

.ilicon-img-extended-file-type-adobe-32  {
  background-image: url(../images/ilicon-extended-32/file-type-adobe.png);
}

.ilicon-img-extended-file-type-excel-32  {
  background-image: url(../images/ilicon-extended-32/file-type-excel.png);
}

.ilicon-img-extended-file-type-file-32  {
  background-image: url(../images/ilicon-extended-32/file-type-file.png);
}

.ilicon-img-extended-file-type-image-32  {
  background-image: url(../images/ilicon-extended-32/file-type-image.png);
}

.ilicon-img-extended-file-type-ppt-32  {
  background-image: url(../images/ilicon-extended-32/file-type-ppt.png);
}

.ilicon-img-extended-file-type-word-32  {
  background-image: url(../images/ilicon-extended-32/file-type-word.png);
}

.ilicon-img-extended-file-type-zip-32  {
  background-image: url(../images/ilicon-extended-32/file-type-zip.png);
}

.ilicon-img-extended-offer-issued-32  {
  background-image: url(../images/ilicon-extended-32/offer-issued.svg);
}

.ilicon-img-extended-offer-issued-green-32  {
  background-image: url(../images/ilicon-extended-32/offer-issued-green.svg);
}

.ilicon-img-extended-offer-issued-grey-32  {
  background-image: url(../images/ilicon-extended-32/offer-issued-grey.svg);
}

.ilicon-img-extended-mortgage-completed-32  {
  background-image: url(../images/ilicon-extended-32/mortgage-completed.svg);
}

.ilicon-img-extended-valuation-received-purple-32  {
  width: 36px;
  height: 36px;
  background-image: url(../images/ilicon-extended-32/valuation-received-purple.svg);
}

.ilicon-img-extended-valuation-received-red-32  {
  width: 36px;
  height: 36px;
  background-image: url(../images/ilicon-extended-32/valuation-received-red.svg);
}

.ilicon-img-extended-valuation-received-white-32  {
  width: 36px;
  height: 36px;
  background-image: url(../images/ilicon-extended-32/valuation-received-white.svg);
}

.ilicon-img-extended-valuation-received-grey-32  {
  width: 36px;
  height: 36px;
  background-image: url(../images/ilicon-extended-32/valuation-received-grey.svg);
}

.ilicon-img-extended-valuation-received-green-32  {
  width: 36px;
  height: 36px;
  background-image: url(../images/ilicon-extended-32/valuation-received-green.svg);
}