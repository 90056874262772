.ilicon-img-red-32 {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/red-32/sprite.svg);
}
.ilicon-img-access-report-red-32 {
  width: 32px;
  height: 32px;
  background-position: 0 0;
}
.ilicon-img-admin-red-32 {
  width: 32px;
  height: 32px;
  background-position: -48px 0;
}
.ilicon-img-admin-warning-1-red-32 {
  width: 32px;
  height: 32px;
  background-position: -96px 0;
}
.ilicon-img-acrobat-red-32 {
  width: 32px;
  height: 32px;
  background-position: -144px 0;
}
.ilicon-img-caret-red-32 {
  width: 32px;
  height: 32px;
  background-position: -192px 0;
}
.ilicon-img-caret-down-red-32 {
  width: 32px;
  height: 32px;
  background-position: -240px 0;
}
.ilicon-img-check-red-32 {
  width: 32px;
  height: 32px;
  background-position: -288px 0;
}
.ilicon-img-chevron-down-red-32 {
  width: 32px;
  height: 32px;
  background-position: -336px 0;
}
.ilicon-img-chevron-left-red-32 {
  width: 32px;
  height: 32px;
  background-position: -384px 0;
}
.ilicon-img-chevron-right-red-32 {
  width: 32px;
  height: 32px;
  background-position: -432px 0;
}
.ilicon-img-chevron-up-red-32 {
  width: 32px;
  height: 32px;
  background-position: -480px 0;
}
.ilicon-img-close2-red-32 {
  width: 32px;
  height: 32px;
  background-position: -528px 0;
}
.ilicon-img-filter-applied-red-32 {
  width: 32px;
  height: 32px;
  background-position: -576px 0;
}
.ilicon-img-cloud-upload-mobile-red-32 {
  width: 32px;
  height: 32px;
  background-position: -624px 0;
}
.ilicon-img-comments-red-32 {
  width: 32px;
  height: 32px;
  background-position: -672px 0;
}
.ilicon-img-confirm-red-32 {
  width: 32px;
  height: 32px;
  background-position: -720px 0;
}
.ilicon-img-copy-red-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -48px;
}
.ilicon-img-single-line-red-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -48px;
}
.ilicon-img-delete-red-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -48px;
}
.ilicon-img-descend-red-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -48px;
}
.ilicon-img-doc_move-red-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -48px;
}
.ilicon-img-doc-add-red-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -48px;
}
.ilicon-img-doc-extension-red-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -48px;
}
.ilicon-img-document-red-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -48px;
}
.ilicon-img-doc-updated-red-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -48px;
}
.ilicon-img-doc-upload-red-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -48px;
}
.ilicon-img-download-red-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -48px;
}
.ilicon-img-download-doc-red-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -48px;
}
.ilicon-img-downloaded-indicator-red-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -48px;
}
.ilicon-img-download-offline-red-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -48px;
}
.ilicon-img-edit-red-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -48px;
}
.ilicon-img-eforms-red-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -48px;
}
.ilicon-img-ellipsis-red-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -96px;
}
.ilicon-img-excel-logo-red-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -96px;
}
.ilicon-img-export-details-red-32 {
  width: 32px;
  height: 32px;
  background-position: -96px -96px;
}
.ilicon-img-hamburger-red-32 {
  width: 32px;
  height: 32px;
  background-position: -144px -96px;
}
.ilicon-img-help-red-32 {
  width: 32px;
  height: 32px;
  background-position: -192px -96px;
}
.ilicon-img-image-red-32 {
  width: 32px;
  height: 32px;
  background-position: -240px -96px;
}
.ilicon-img-logout-red-32 {
  width: 32px;
  height: 32px;
  background-position: -288px -96px;
}
.ilicon-img-mail-red-32 {
  width: 32px;
  height: 32px;
  background-position: -336px -96px;
}
.ilicon-img-minus-red-32 {
  width: 32px;
  height: 32px;
  background-position: -384px -96px;
}
.ilicon-img-move-red-32 {
  width: 32px;
  height: 32px;
  background-position: -432px -96px;
}
.ilicon-img-plus-red-32 {
  width: 32px;
  height: 32px;
  background-position: -480px -96px;
}
.ilicon-img-ppt-logo-red-32 {
  width: 32px;
  height: 32px;
  background-position: -528px -96px;
}
.ilicon-img-ilicon_picture-red-32 {
  width: 32px;
  height: 32px;
  background-position: -576px -96px;
}
.ilicon-img-reply-red-32 {
  width: 32px;
  height: 32px;
  background-position: -624px -96px;
}
.ilicon-img-search-red-32 {
  width: 32px;
  height: 32px;
  background-position: -672px -96px;
}
.ilicon-img-sync-red-32 {
  width: 32px;
  height: 32px;
  background-position: -720px -96px;
}
.ilicon-img-user-red-32 {
  width: 32px;
  height: 32px;
  background-position: 0 -144px;
}
.ilicon-img-word-logo-red-32 {
  width: 32px;
  height: 32px;
  background-position: -48px -144px;
}

