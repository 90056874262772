.ilicon-img-red-128 {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/red-128/sprite.svg);
}
.ilicon-img-access-report-red-128 {
  width: 128px;
  height: 128px;
  background-position: 0 0;
}
.ilicon-img-admin-red-128 {
  width: 128px;
  height: 128px;
  background-position: -144px 0;
}
.ilicon-img-admin-warning-1-red-128 {
  width: 128px;
  height: 128px;
  background-position: -288px 0;
}
.ilicon-img-acrobat-red-128 {
  width: 128px;
  height: 128px;
  background-position: -432px 0;
}
.ilicon-img-caret-red-128 {
  width: 128px;
  height: 128px;
  background-position: -576px 0;
}
.ilicon-img-caret-down-red-128 {
  width: 128px;
  height: 128px;
  background-position: -720px 0;
}
.ilicon-img-check-red-128 {
  width: 128px;
  height: 128px;
  background-position: -864px 0;
}
.ilicon-img-chevron-down-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1008px 0;
}
.ilicon-img-chevron-left-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1152px 0;
}
.ilicon-img-chevron-right-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1296px 0;
}
.ilicon-img-chevron-up-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1440px 0;
}
.ilicon-img-close2-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1584px 0;
}
.ilicon-img-filter-applied-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1728px 0;
}
.ilicon-img-cloud-upload-mobile-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1872px 0;
}
.ilicon-img-comments-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2016px 0;
}
.ilicon-img-confirm-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2160px 0;
}
.ilicon-img-copy-red-128 {
  width: 128px;
  height: 128px;
  background-position: 0 -144px;
}
.ilicon-img-single-line-red-128 {
  width: 128px;
  height: 128px;
  background-position: -144px -144px;
}
.ilicon-img-delete-red-128 {
  width: 128px;
  height: 128px;
  background-position: -288px -144px;
}
.ilicon-img-descend-red-128 {
  width: 128px;
  height: 128px;
  background-position: -432px -144px;
}
.ilicon-img-doc_move-red-128 {
  width: 128px;
  height: 128px;
  background-position: -576px -144px;
}
.ilicon-img-doc-add-red-128 {
  width: 128px;
  height: 128px;
  background-position: -720px -144px;
}
.ilicon-img-doc-extension-red-128 {
  width: 128px;
  height: 128px;
  background-position: -864px -144px;
}
.ilicon-img-document-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1008px -144px;
}
.ilicon-img-doc-updated-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1152px -144px;
}
.ilicon-img-doc-upload-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1296px -144px;
}
.ilicon-img-download-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1440px -144px;
}
.ilicon-img-download-doc-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1584px -144px;
}
.ilicon-img-downloaded-indicator-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1728px -144px;
}
.ilicon-img-download-offline-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1872px -144px;
}
.ilicon-img-edit-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2016px -144px;
}
.ilicon-img-eforms-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2160px -144px;
}
.ilicon-img-ellipsis-red-128 {
  width: 128px;
  height: 128px;
  background-position: 0 -288px;
}
.ilicon-img-excel-logo-red-128 {
  width: 128px;
  height: 128px;
  background-position: -144px -288px;
}
.ilicon-img-export-details-red-128 {
  width: 128px;
  height: 128px;
  background-position: -288px -288px;
}
.ilicon-img-hamburger-red-128 {
  width: 128px;
  height: 128px;
  background-position: -432px -288px;
}
.ilicon-img-help-red-128 {
  width: 128px;
  height: 128px;
  background-position: -576px -288px;
}
.ilicon-img-image-red-128 {
  width: 128px;
  height: 128px;
  background-position: -720px -288px;
}
.ilicon-img-logout-red-128 {
  width: 128px;
  height: 128px;
  background-position: -864px -288px;
}
.ilicon-img-mail-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1008px -288px;
}
.ilicon-img-minus-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1152px -288px;
}
.ilicon-img-move-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1296px -288px;
}
.ilicon-img-plus-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1440px -288px;
}
.ilicon-img-ppt-logo-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1584px -288px;
}
.ilicon-img-ilicon_picture-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1728px -288px;
}
.ilicon-img-reply-red-128 {
  width: 128px;
  height: 128px;
  background-position: -1872px -288px;
}
.ilicon-img-search-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2016px -288px;
}
.ilicon-img-sync-red-128 {
  width: 128px;
  height: 128px;
  background-position: -2160px -288px;
}
.ilicon-img-user-red-128 {
  width: 128px;
  height: 128px;
  background-position: 0 -432px;
}
.ilicon-img-word-logo-red-128 {
  width: 128px;
  height: 128px;
  background-position: -144px -432px;
}

