.page-break {
    display: none;
}

@page {
    size: A4;
    margin: 0.5cm;
}

@media all {
    #cpf-print {
        height: 100%;
        width: 100%;

        .color-red {
            color: #E61E2D !important;
        }

        .color-brown {
            color: #F49619 !important;
        }

        .header {
            width: 100%;
            height: 60px;
            background: #E2E1E1;
            background-color: #E2E1E1 !important;

            .header-logo {
                width: 220px;
                height: 60px;
                padding: 0;
                margin: 0;
                position: relative;
                background: url(../images/Logo-res.png);
                background-size: 230px;
                background-repeat: no-repeat;
            }
        }

        .view-margin {
            margin-bottom: 20px;
        }

        .form-description {
            color: #7d7d7d !important;
        }

        .content {
            padding: 25px 5px;

            .page-header {
                margin: 40px 0 20px;
                padding: 0;
                border-bottom: 2px solid #e0e0e0;
                margin-top: 0;
                width: 100%;
            }

            .title-font {
                font-size: 2em;
            }

            .view-header {
                border-bottom: 2px solid #e0e0e0;
                padding: 5px 0;
                margin-bottom: 10px;
                padding-bottom: 15px;
                display: inline-block;
                width: 100%;
            }

            .view-table {
                width: 100%;
                height: 100%;

                tr {
                    width: 100%;
                }
            }

            .personal-box {
                margin: 8px 0;
                font-size: 1.5em;

                .personal-icon {
                    padding: 10px 0;
                    height: 100%;
                    text-align: center;
                    width: 60px;
                    float: left;
                }

                .personal-content {
                    padding: 0;
                }

                .personal-content label {
                    margin: 0;
                }

                .personal-content p {
                    color: #7d7d7d !important;
                    font-size: 15px;
                }
            }

            .view-title {
                display: inline-block;
                width: 100%;

                .icon {
                    display: block;
                    float: left;
                    padding: 4px;
                }

                .title {
                    border-bottom: 2px solid #e0e0e0;
                    padding-bottom: 5px;
                    color: #cccccc !important;
                    font-size: 1.2em;
                    font-weight: bold;
                    margin: 0 0 4px 25px;
                }
            }

            .view-content {
                margin-left: 25px;

                .text-danger {
                    color: #E61E2D !important;
                }

                .mobile-new-detail-label {
                    padding-left: 0;
                    text-align: left;
                    margin: 0;
                }
            }

            // icon
            .ilicon-img-red-16 {
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url(../images/ilicon/red-16/sprite.svg) !important;
            }

            .ilicon-img-user-red-16 {
                width: 16px;
                height: 16px;
                background-position: 0 -96px !important;
            }

            .ilicon-img-extended-valuation-received-red-16 {
                width: 16px;
                height: 16px;
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url(../images/ilicon-extended-16/valuation-received-red.svg) !important;
            }

            .ilicon-img-orange-16 {
                display: inline-block;
                background-repeat: no-repeat;
                background-image: url(../images/ilicon/orange-16/sprite.svg) !important;
            }

            .ilicon-img-admin-orange-16 {
                width: 16px;
                height: 16px;
                background-position: -32px 0 !important;
            }
        }
    }
}

@media print {
    .page-break {
        display: block;
        page-break-before: always;
    }

    .clear-print-padding{
        padding: 0;
    }

    .clear-padding {
        padding: 0;
    }

    body {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        margin: 0.5cm;
    }

    .hidden-xs {
        display: block !important; //trickery to fool printers
    }

    .hidden-sm {
        display: none !important;
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8.33333%;
    }

    .col-md-2 {
        width: 16.66667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333%;
    }

    .col-md-5 {
        width: 41.66667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33333%;
    }

    .col-md-8 {
        width: 66.66667%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33333%;
    }

    .col-md-11 {
        width: 91.66667%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: left;
    }

    .col-xs-1 {
        width: 8.33333%;
    }

    .col-xs-2 {
        width: 16.66667%;
    }

    .col-xs-3 {
        width: 25%;
    }

    .col-xs-4 {
        width: 33.33333%;
    }

    .col-xs-5 {
        width: 41.66667%;
    }

    .col-xs-6 {
        width: 50%;
    }

    .col-xs-7 {
        width: 58.33333%;
    }

    .col-xs-8 {
        width: 66.66667%;
    }

    .col-xs-9 {
        width: 75%;
    }

    .col-xs-10 {
        width: 83.33333%;
    }

    .col-xs-11 {
        width: 91.66667%;
    }

    .col-xs-12 {
        width: 100%;
    }

    .form-group {
        margin: 0;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    #portfolio-summary {
        margin: 0;
    }

    #portfolio-summary p {
        color: #00864f !important;
    }

    #portfolio-summary div {
        padding: 0;
    }

    .sub-header-title {
        background: #e7e7e7 !important;
    }

    .header-logo {
        width: 220px !important;
        height: 60px !important;
        padding: 0;
        margin: 0;
        position: relative;
        background: url(../images/Logo-res.png) !important;
        background-size: 230px !important;
        background-repeat: no-repeat;
    }

    .alert-success {
        background-color: #dff0d8 !important;
        border-color: #d6e9c6 !important;
        color: #3c763d !important;
    }

    .alert-success hr {
        border-top-color: #c9e2b3 !important;
    }

    .alert-success .alert-link {
        color: #2b542c !important;
    }

    .alert-info {
        background-color: #d9edf7 !important;
        border-color: #bce8f1 !important;
        color: #31708f !important;
    }

    .alert-info hr {
        border-top-color: #a6e1ec !important;
    }

    .alert-info .alert-link {
        color: #245269 !important;
    }

    .alert-warning {
        background-color: #fcf8e3 !important;
        border-color: #faebcc !important;
        color: #8a6d3b !important;
    }

    .alert-warning hr {
        border-top-color: #f7e1b5 !important;
    }

    .alert-warning .alert-link {
        color: #66512c !important;
    }

    .alert-danger {
        background-color: #f2dede !important;
        border-color: #ebccd1 !important;
        color: #a94442 !important;
    }

    .alert-danger hr {
        border-top-color: #e4b9c0 !important;
    }

    .alert-danger .alert-link {
        color: #843534 !important;
    }

    // icon
    .ilicon-img-red-16 {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon/red-16/sprite.svg) !important;
    }

    .ilicon-img-user-red-16 {
        width: 16px;
        height: 16px;
        background-position: 0 -96px !important;
    }

    .ilicon-img-extended-valuation-received-red-16 {
        width: 16px;
        height: 16px;
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon-extended-16/valuation-received-red.svg) !important;
    }

    .ilicon-img-orange-16 {
        display: inline-block;
        background-repeat: no-repeat;
        background-image: url(../images/ilicon/orange-16/sprite.svg) !important;
    }

    .ilicon-img-admin-orange-16 {
        width: 16px;
        height: 16px;
        background-position: -32px 0 !important;
    }

    #portfolio-summary p {
        color: #00864f !important;
    }

    .avm-not-edit-background {
        background: #e7e7e7 !important;
        height: inherit;
        word-wrap: break-word;
        padding: 5px 0;
        hyphens: auto;
    }

    .avm-table-margin {
        margin-bottom: 20px;
    }

    .avm-table-row {
        height: 45px;
    }

    .printHeight {
        height: 30px !important;
    }

    .grid-row-font-bold {
        white-space: nowrap;
        text-align: left;
        padding: 0;
        margin: 0;
    }

    .content-before::before {
        content: '';
        display: block;
        page-break-before: always;
    }

    .view-before-app:not(:last-child)::after,
    .view-before-buy:not(:last-child)::after,
    .view-before-res:not(:last-child)::after {
        content: '';
        display: block;
        page-break-before: always;
    }
}