.ilicon-img-black-64  {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../images/ilicon/black-64/sprite.svg);
}
.ilicon-img-access-report-black-64 {
  width: 64px;
  height: 64px;
  background-position: 0 0;
}
.ilicon-img-admin-black-64 {
  width: 64px;
  height: 64px;
  background-position: -80px 0;
}
.ilicon-img-admin-warning-1-black-64 {
  width: 64px;
  height: 64px;
  background-position: -160px 0;
}
.ilicon-img-acrobat-black-64 {
  width: 64px;
  height: 64px;
  background-position: -240px 0;
}
.ilicon-img-caret-black-64 {
  width: 64px;
  height: 64px;
  background-position: -320px 0;
}
.ilicon-img-caret-down-black-64 {
  width: 64px;
  height: 64px;
  background-position: -400px 0;
}
.ilicon-img-check-black-64 {
  width: 64px;
  height: 64px;
  background-position: -480px 0;
}
.ilicon-img-chevron-down-black-64 {
  width: 64px;
  height: 64px;
  background-position: -560px 0;
}
.ilicon-img-chevron-left-black-64 {
  width: 64px;
  height: 64px;
  background-position: -640px 0;
}
.ilicon-img-chevron-right-black-64 {
  width: 64px;
  height: 64px;
  background-position: -720px 0;
}
.ilicon-img-chevron-up-black-64 {
  width: 64px;
  height: 64px;
  background-position: -800px 0;
}
.ilicon-img-close2-black-64 {
  width: 64px;
  height: 64px;
  background-position: -880px 0;
}
.ilicon-img-filter-applied-black-64 {
  width: 64px;
  height: 64px;
  background-position: -960px 0;
}
.ilicon-img-cloud-upload-mobile-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1040px 0;
}
.ilicon-img-comments-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1120px 0;
}
.ilicon-img-confirm-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1200px 0;
}
.ilicon-img-copy-black-64 {
  width: 64px;
  height: 64px;
  background-position: 0 -80px;
}
.ilicon-img-single-line-black-64 {
  width: 64px;
  height: 64px;
  background-position: -80px -80px;
}
.ilicon-img-delete-black-64 {
  width: 64px;
  height: 64px;
  background-position: -160px -80px;
}
.ilicon-img-descend-black-64 {
  width: 64px;
  height: 64px;
  background-position: -240px -80px;
}
.ilicon-img-doc_move-black-64 {
  width: 64px;
  height: 64px;
  background-position: -320px -80px;
}
.ilicon-img-doc-add-black-64 {
  width: 64px;
  height: 64px;
  background-position: -400px -80px;
}
.ilicon-img-doc-extension-black-64 {
  width: 64px;
  height: 64px;
  background-position: -480px -80px;
}
.ilicon-img-document-black-64 {
  width: 64px;
  height: 64px;
  background-position: -560px -80px;
}
.ilicon-img-doc-updated-black-64 {
  width: 64px;
  height: 64px;
  background-position: -640px -80px;
}
.ilicon-img-doc-upload-black-64 {
  width: 64px;
  height: 64px;
  background-position: -720px -80px;
}
.ilicon-img-download-black-64 {
  width: 64px;
  height: 64px;
  background-position: -800px -80px;
}
.ilicon-img-download-doc-black-64 {
  width: 64px;
  height: 64px;
  background-position: -880px -80px;
}
.ilicon-img-downloaded-indicator-black-64 {
  width: 64px;
  height: 64px;
  background-position: -960px -80px;
}
.ilicon-img-download-offline-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1040px -80px;
}
.ilicon-img-edit-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1120px -80px;
}
.ilicon-img-eforms-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1200px -80px;
}
.ilicon-img-ellipsis-black-64 {
  width: 64px;
  height: 64px;
  background-position: 0 -160px;
}
.ilicon-img-excel-logo-black-64 {
  width: 64px;
  height: 64px;
  background-position: -80px -160px;
}
.ilicon-img-export-details-black-64 {
  width: 64px;
  height: 64px;
  background-position: -160px -160px;
}
.ilicon-img-hamburger-black-64 {
  width: 64px;
  height: 64px;
  background-position: -240px -160px;
}
.ilicon-img-help-black-64 {
  width: 64px;
  height: 64px;
  background-position: -320px -160px;
}
.ilicon-img-image-black-64 {
  width: 64px;
  height: 64px;
  background-position: -400px -160px;
}
.ilicon-img-logout-black-64 {
  width: 64px;
  height: 64px;
  background-position: -480px -160px;
}
.ilicon-img-mail-black-64 {
  width: 64px;
  height: 64px;
  background-position: -560px -160px;
}
.ilicon-img-minus-black-64 {
  width: 64px;
  height: 64px;
  background-position: -640px -160px;
}
.ilicon-img-move-black-64 {
  width: 64px;
  height: 64px;
  background-position: -720px -160px;
}
.ilicon-img-plus-black-64 {
  width: 64px;
  height: 64px;
  background-position: -800px -160px;
}
.ilicon-img-ppt-logo-black-64 {
  width: 64px;
  height: 64px;
  background-position: -880px -160px;
}
.ilicon-img-ilicon_picture-black-64 {
  width: 64px;
  height: 64px;
  background-position: -960px -160px;
}
.ilicon-img-reply-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1040px -160px;
}
.ilicon-img-search-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1120px -160px;
}
.ilicon-img-sync-black-64 {
  width: 64px;
  height: 64px;
  background-position: -1200px -160px;
}
.ilicon-img-user-black-64 {
  width: 64px;
  height: 64px;
  background-position: 0 -240px;
}
.ilicon-img-word-logo-black-64 {
  width: 64px;
  height: 64px;
  background-position: -80px -240px;
}

